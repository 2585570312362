!(function ($) {
    'use strict';

    const form = $('#contact');
    const formMessages = $('#form-messages');

    $('#modal-contact').on('hidden.bs.modal', function (e) {
        $(formMessages).hide();
    });

    $(form).submit(function (event) {
        event.preventDefault();
        const formData = $(form).serialize();

        $.ajax({
            type: 'POST',
            url: $(form).attr('action'),
            data: formData
        }).done(function (response) {
            $(formMessages).removeClass('alert-error').addClass('alert-success').text(response).fadeIn();

            $('input, textarea', form).val('');
        }).fail(function (data) {
            $(formMessages).fadeDown(function () {
                $(formMessages).removeClass('alert-success').addClass('alert-error');

                if (data.responseText !== '') {
                    $(formMessages).text(data.responseText);
                } else {
                    $(formMessages).text('Hoppla! Ein Fehler ist aufgetreten und Ihre Nachricht konnte nicht gesendet werden.');
                }

                $(formMessages).fadeIn();
            });
        });
    });
})(jQuery);
